
  import { defineComponent, watch, nextTick } from "vue";
  import useAddress from "@/hooks/useAddress";
  export default defineComponent({
    name: "addressCard",
    emit: ['handle-del'],
    props: {
      list: {
        type: Array,
        default: [] as object[]
      },
    },
    setup(props, ctx) {

      const refs: Array<HTMLElement> = [];

      const addressCard = (el: HTMLElement) => {
        refs.push(el);
      }

      watch(() => props.list, ()=>{
        console.log("list",props.list)
        if(props.list.length > 0){
          nextTick(() => {
            props.list.map((item, index) => {
              useAddress(refs[index])
            })
          })
        }
      })

      function delAddress(id: string){
        ctx.emit("handle-del", id);
      }

      return {
        addressCard,
        delAddress
      };
    },
  });
